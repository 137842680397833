import React, { useState, useEffect } from "react";
import axios from 'axios';
import PropTypes from 'prop-types';
import { Navbar, Nav, Container, NavDropdown, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { removeAuthUser, getAuthUser } from '../Helper/Storage';
import './ankh.css';
import logo from '../Images/logo.png';
import { useMediaQuery } from 'react-responsive';
import { FaFlag } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const Header = ({ mode, toggleColorMode }) => {
  const { t, i18n } = useTranslation();
  const Auth = getAuthUser();
  const navigate = useNavigate();

  const isSurfacePro7OrLess = useMediaQuery({ query: '(max-width: 1024px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const [destinations, setDestinations] = useState([]);
  const [newDestination, setNewDestination] = useState('');
  const [error, setError] = useState('');

  const Logout = () => {
    removeAuthUser();
    navigate('/');
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language).catch((error) => {
      console.error("Language change failed", error);
    });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.goldenpharaohtours.com/deletedestination/${id}`);
      fetchDestinations(); // Refresh the list after deletion
    } catch (error) {
      setError('Error deleting destination. Please try again.');
      console.error('Error deleting destination:', error);
    }
  };


  const handleAddDestination = async () => {
    if (!newDestination) return;

    try {
      await axios.post('https://api.goldenpharaohtours.com/createdestination', { name: newDestination });
      setNewDestination(''); // Clear input after submission
      fetchDestinations(); // Refresh the list after addition
    } catch (error) {
      setError('Error adding destination. Please try again.');
      console.error('Error adding destination:', error);
    }
  };

  const fetchDestinations = async () => {
    try {
      const response = await axios.get('https://api.goldenpharaohtours.com/getdestinations');
      setDestinations(response.data);
    } catch (error) {
      setError('Failed to fetch destinations. Please try again.');
      console.error("Failed to fetch destinations", error);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  return (
    <div className={mode === 'dark' ? 'dark-mode' : 'light-mode'}>
      <Navbar expand="lg" fixed="top" className="custom-navbar" bg={mode} variant={mode}>
        <Container fluid className='fluid'>
          <Navbar.Brand as={Link} to="/" className="navbar-brand">
            <img src={logo} alt="Logo" style={{ height: '40px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" style={{ textAlign: 'left' }}>
              <Nav.Link as={Link} to="/">{t('home')}</Nav.Link>
              <Nav.Link as={Link} to="/about">{t('about_us')}</Nav.Link>
              <Nav.Link as={Link} to="/blog">{t('Blogs')}</Nav.Link>

              {/* Destinations Dropdown */}
              <NavDropdown title={t('destinations')} id="navbarScrollingDropdown">
                <NavDropdown.Item as={Link} to="/TripsCards">{t('All')}</NavDropdown.Item>
                {destinations.map((dest, index) => (
                  <NavDropdown.Item
                    key={index}
                    href={`/Tripscards?category=${dest.name}`}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    onClick={(event) => event.stopPropagation()} // Prevent navigation when clicking anywhere in the item
                  >
                    <span>{t(dest.name.toLowerCase())}</span> {/* Text container */}
                    {Auth && Auth[0].status === 1 && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={(event) => {
                          event.stopPropagation();  // Prevent the NavDropdown.Item click
                          event.preventDefault();   // Prevent the default href navigation
                          handleDelete(dest.id);
                        }}
                        style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                      />
                    )}
                  </NavDropdown.Item>
                ))}



                <NavDropdown.Divider />
                {Auth && Auth[0].status === 1 && (
                  <Form inline style={{ padding: '10px' }}>
                    <Form.Control
                      type="text"
                      placeholder="Add destination"
                      value={newDestination}
                      onChange={(e) => setNewDestination(e.target.value)}
                      style={{
                        marginRight: '10px',
                        borderRadius: '5px',
                        padding: '10px', // Increase padding for better visibility
                        border: '2px solid #007bff', // Thicker border with a noticeable color
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                        fontWeight: 'bold', // Make the text bold
                        backgroundColor: '#f8f9fa', // Light background color for contrast
                        color: '#343a40' // Darker text color for readability
                      }}
                    />

                    <Button
                      variant="primary"
                      onClick={handleAddDestination}
                      style={{ borderRadius: '5px' }}
                    >
                      Add
                    </Button>
                  </Form>
                )}
                {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
              </NavDropdown>

              {/* Categories Dropdown */}
              <NavDropdown title={t('categories')} id="navbarScrollingDropdown">
                <NavDropdown.Item href="/Tripscards?category=all">{t('all')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=FullDay">{t('full_day')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=HalfDay">{t('half_day')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=Nighttours">{t('night_tours')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=Packages">{t('Packages')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=SpecialOffers">{t('special_offers')}</NavDropdown.Item>
                <NavDropdown.Item href="/Tripscards?category=VIP">{t('VIP')}</NavDropdown.Item>
              </NavDropdown>

              {/* Management Links for Auth Users */}
              {Auth && Auth[0].status === 1 && (
                <>
                  <Nav.Link as={Link} to="/managetrips">{t('manage_trips')}</Nav.Link>
                  <Nav.Link as={Link} to="/managebookings">{t('manage_bookings')}</Nav.Link>
                  <Nav.Link as={Link} to="/ManageArticle">{t('Manage Blogs')}</Nav.Link>
                </>
              )}

              {/* Language Selector */}
              <NavDropdown title={<FaFlag style={{ marginRight: '8px', color: 'orange' }} />} id="navbarScrollingDropdown">
                <NavDropdown.Item onClick={() => changeLanguage('en')}>{t('english')}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('es')}>{t('spanish')}</NavDropdown.Item>
              </NavDropdown>
            </Nav>

            {Auth && <Nav.Link onClick={Logout} className='d-flex ms-auto btn btn-sm btn-danger' style={{ marginRight: '15px' }}>Logout</Nav.Link>}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default Header;