import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import "../../Assets/css/spinner.css";
import { useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const CreateArticle = () => {
    const [articleId, setArticleId] = useState(null); // State to store article ID
    const [loading, setLoading] = useState(false);
    const [article, setArticle] = useState({
        title: "",
        descreption: "", // Changed from 'description' to 'descreption'
        err: []
    });

    const navigate = useNavigate();
    const params = useParams(); // Access route parameters

    useEffect(() => {
        // Check if article ID is provided in params (indicating update mode)
        if (params.id) {
            setArticleId(params.id);
            // No need to fetch article details now
        }
    }, [params.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Update existing article
            const response = await axios.put(`https://api.goldenpharaohtours.com/updatearticals/${articleId}`, {
                title: article.title,
                descreption: article.descreption // Send as 'descreption' to match API requirements
            });

            console.log("Article updated:", response.data);
            navigate("/"); // Navigate to home or article list page after successful submission
        } catch (error) {
            console.error("Error updating article:", error);
            const errorMessage = error.response?.data?.message || "An error occurred while updating.";
            setArticle({ ...article, err: [errorMessage] });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-post-container">
            <br /><br /><br />
            <Card className="create-post-card">
                <Card.Body>
                    <h1>Update Blog</h1>
                    {article.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={handleSubmit} className="post-form">
                        <Form.Group className="article-title-group">
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={article.title}
                                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                                placeholder="Enter Blog title"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Blog Descreption</Form.Label> {/* Changed from 'Description' to 'Descreption' */}
                            <Form.Control
                                as="textarea"
                                value={article.descreption} // Updated to 'descreption'
                                onChange={(e) => setArticle({ ...article, descreption: e.target.value })} // Updated to 'descreption'
                                rows={5}
                                placeholder="Write Blog descreption here..." // Updated to 'descreption'
                                minLength={15}
                            />
                            {article.descreption.length > 0 && article.descreption.length < 15 && ( // Updated to 'descreption'
                                <Alert variant="danger">
                                    Descreption must be at least 15 characters long. {/* Updated to 'Descreption' */}
                                </Alert>
                            )}
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? "Updating..." : "Update Article"}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CreateArticle;
