import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import "../../Assets/css/spinner.css";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

const CreateArticle = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState([]);

    const navigate = useNavigate();

    // Fix the typo in 'description'
    const [article, setArticle] = useState({
        title: "",
        description: "", // Changed from 'descreption'
        err: []
    });

    const Do_Post = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("title", article.title);
        formData.append("descreption", article.description); // Send as 'descreption' to match API requirements

        // Append multiple images properly
        for (let i = 0; i < images.length; i++) {
            formData.append("images", images[i]);
        }

        setLoading(true);

        try {
            const response = await axios.post("https://api.goldenpharaohtours.com/createarticals", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            navigate("/");
            setArticle({ ...article, loading: false, err: [] });
        } catch (error) {
            console.log(error);

            let errorMessage;

            if (error.response && error.response.status === 400) {
                console.log("Error response data:", error.response.data);
                errorMessage = error.response.data?.message || "Bad Request: Please check your input.";
            } else if (error.code === 'ERR_NETWORK') {
                errorMessage = "Network error: Please check your internet connection.";
            } else {
                errorMessage = error.message || "An error occurred.";
            }

            setArticle({ ...article, loading: false, err: [errorMessage] });
        } finally {
            setLoading(false); // Simplified loading management
        }


    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        setImages(files);

        const imagesPreview = [];
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () => {
                imagesPreview.push(reader.result);
                if (imagesPreview.length === files.length) {
                    setPreview(imagesPreview);
                }
            };
        }
    };

    const handleResetClick = () => {
        setPreview([]); // Reset preview to an empty array
        setImages([]);
    };

    return (
        <div className="create-post-container">
            <br></br><br></br><br></br>
            <Card className="create-post-card">
                <Card.Body>
                    <h1>Create Blog</h1>
                    {article.err.map((error, index) => (
                        <Alert key={index} variant='danger'>
                            {error}
                        </Alert>
                    ))}
                    <Form onSubmit={Do_Post} className="post-form">
                        <Form.Group className="article-title-group">
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={article.title}
                                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                                placeholder="Enter Blog title"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Blog Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={article.description}
                                onChange={(e) => setArticle({ ...article, description: e.target.value })}
                                rows={5}
                                placeholder="Write Blog description here..."
                                required
                                minLength={15} // Ensures minimum 15 characters
                            />
                            {article.description.length > 0 && article.description.length < 15 && (
                                <Alert variant="danger">
                                    Description must be at least 15 characters long.
                                </Alert>
                            )}
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label>Upload Blog Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                multiple
                            />
                            {preview && preview.length > 0 && preview.map((image, index) => (
                                <Image key={index} src={image} alt={`Preview ${index}`} thumbnail />
                            ))}
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button variant="secondary" onClick={handleResetClick}>Reset Images</Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? "Creating..." : "Create Article"}
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
};

export default CreateArticle;
