import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../Assets/css/spinner.css';
import sphinx from "../../Images/sunrise.jpg";
import camel from "../../Images/camel.jpg";
import sand from "../../Images/sunrise2.jpg";
import image1 from "../../Images/sand.jpg";
import image2 from "../../Images/py.jpg";
import image3 from "../../Images/sphinx.jpg";
import pyramid from "../../Images/pyramid.mp4";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Reviews from '../Reviews/Reviews';
import { useMediaQuery } from 'react-responsive';
import './home.css';
import UsePageSEO from '../../hooks/UsePageSEO/index';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const Home = () => {
  UsePageSEO({
    title: "Best Egypt Tour Packages from USA | Golden Pharaoh’s Tours",
    description: "Join our best Egypt vacation packages from the USA. Enjoy unique experiences, guided tours, and comfortable accommodations. Start planning your journey",
  });

  const { t } = useTranslation();
  const [tripsState, setTopTrips] = useState({
    loading: true,
    results: [],
    err: null,
    reload: "0"
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    setTopTrips(prevState => ({ ...prevState, loading: true }));
    axios.get("https://api.goldenpharaohtours.com/toptrip", {})
      .then(resp => {
        setTopTrips(prevState => ({ ...prevState, results: resp.data, loading: false, err: null }));
      })
      .catch(() => {
        setTopTrips(prevState => ({ ...prevState, loading: false, results: [], err: null }));
      });
  }, [tripsState.reload]);

  return (
    <div className="site-wrap">
      <div className="site-section">
        <div className="bg-video">
          <video autoPlay muted loop style={{ width: '100%', height: '400px', objectFit: 'cover' }}>
            <source src={pyramid} type="video/mp4" />
          </video>
          <Container className="text-center text-white py-5" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <h2 className="text-center text-white py-5" style={{ color: 'white' }}>{t('Our Destinations')}</h2>
            <p className="text-center text-white " style={{ color: 'white' }}>{t('Choose Your Next Destination')}</p>
            <Form>
              <Form.Group className="mb-3 d-flex justify-content-center" controlId="search">
                <Form.Control type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('Your Next Trip')} />
              </Form.Group>
            </Form>
          </Container>
        </div>

        <StaticTripCards t={t} tripsState={tripsState} />

        <FeatureCards t={t} />

        <PackageCategories t={t} />

        <HowItWorks t={t} />

        <Reviews limit={4} />
        
        <AboutSection t={t} />
      </div>
    </div>
  );
};

const StaticTripCards = ({ t, tripsState }) => {
  return (
    <Container className="my-5">
    <Row className="justify-content-center text-center mb-4">
      <Col lg={6}>
        <h2 className="font-weight-light" style={{ color: 'orange' }}>{t('Every Missing Moment')}</h2>
        <p>{t('Top Trips')}</p>
      </Col>
    </Row>
    {tripsState.loading ? (
      <div className="loading-spinner-overlay">
        <div className="loading-spinner-container">
          <div className="loading-spinner">&#9765;</div>
          <span>Loading...</span>
        </div>
      </div>
    ) : tripsState.results.length > 0 ? (
      <Row className="justify-content-center">
        {tripsState.results.slice(0, 6).map((trip, index) => (
          <Col key={index} md={4} className="d-flex align-items-stretch">
            <div className="blog-card" style={{ width: '100%', height: '100%' }}>
              <div className="meta" style={{ height: '200px', overflow: 'hidden' }}>
                <img
                  src={`https://api.goldenpharaohtours.com/uploads/${trip.img}`}
                  alt={`Trip ${index}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <div className="description">
                <h2>{trip.name}</h2>
                <p>{truncateText(trip.description, 60)}</p>
                <Link to={`/TripsDetails/${trip.id}`}>
                  <Button variant="warning">Book NOW</Button>
                </Link>
                <div className="author">
                  <img src={trip.authorImage} alt={trip.authorName} />
                  <span className="name">{trip.authorName}</span>
                  <span className="date">{trip.date}</span>
                </div>
              </div>
            </div>
          </Col>
        ))}
        
      
      </Row>
      
    ) : (
      <Alert variant="danger">{t('No Trips Available, try again later.')}</Alert>
    )}
      
    


  </Container>
  
  );
};


const FeatureCards = ({ t }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  const flipCardStyle = {
    marginBottom: '1rem',
    width: isMobile ? '100%' : 'auto', // Full width on mobile, auto on larger screens
  };

  const textCardStyle = {
    marginBottom: '1rem',
    width: '100%',
  };

  const flipCardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: isTabletOrMobile ? 'center' : 'flex-start',
  };

  const textCardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
  };

  return (
    <Container className="my-5">
      <Row className="feature-cards-wrapper">
        <h1 style={{ color: 'orange' }}>{t('Why Choose Us?')}</h1>
        <Col md={6}>
          <div className="feature-card-flip" style={flipCardContainerStyle}>
            <Card
              className="feature-card text-white text-center bg-primary mb-3"
              style={{ ...flipCardStyle, backgroundImage: `url(${sphinx})` }}
            >
              <Card.Body>
                <Card.Title>{t('Why Choose Us?')}</Card.Title>
                <Card.Text>{t("Expert Guides: Our knowledgeable and friendly guides are passionate about the history and culture of Egypt. They are committed to providing informative and engaging tours that bring the past to life.")}</Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="feature-card text-white text-center bg-primary mb-3 feature-card-back"
              style={{ ...flipCardStyle, backgroundImage: `url(${sand})` }}
            >
              <Card.Body>
                <Card.Title>{t('Personalized Service')}</Card.Title>
                <Card.Text>
                  {t("We pride ourselves on offering personalized service to every client. From the moment you book with us until your journey ends, we ensure that every detail is taken care of.")}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col md={6} className="order-1">
          <div className="text-card-container" style={textCardContainerStyle}>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{ color: 'orange' }}>{t('Our Story')}</Card.Title>
                <Card.Text>
                  {t('Founded in 2024, golden pharaoh’s tours was born out of a passion for showcasing the splendor of Egypt to the world. With years of experience in the travel and tourism industry, our team of experts combines local knowledge with a global perspective to create tours that are both authentic and immersive.')}
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="feature-card text-center" style={textCardStyle}>
              <Card.Body>
                <Card.Title style={{ color: 'orange' }}>{t('Customer Satisfaction')}</Card.Title>
                <Card.Text>
                  {t('We strive to exceed our customers’ expectations. Our goal is to provide memorable experiences that leave you with lasting memories of Egypt.')}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const PackageCategories = ({ t }) => {
  // Implement this component as needed
  return null;
};

const HowItWorks = ({ t }) => {
  // Implement this component as needed
  return null;
};

const AboutSection = ({ t }) => {
  // Implement this component as needed
  return null;
};

export default Home;
