import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Alert, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../trips/Tripscards'; // Ensure your CSS file is correctly named and imported
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'; // Import query-string to parse query parameters

const TripsCards = () => {
  const { t } = useTranslation();
  const locationObj = useLocation(); // Rename location to locationObj
  const [trips, setTrips] = useState({
    loading: true,
    results: [],
    err: null,
    reload: "0"
  });

  const [currentPage, setCurrentPage] = useState(1);
  const tripsPerPage = 5; // Number of trips per page

  // Extract category from query parameters
  const queryParams = queryString.parse(locationObj.search); // Use locationObj here
  let category = queryParams.category || '';

  // If category is 'All', set it to an empty string
  if (category === 'All' || category === 'all') {
    category = '';
  }

  useEffect(() => {
    setTrips(prevState => ({ ...prevState, loading: true }));
    axios.get("https://api.goldenpharaohtours.com/tripsgetall", {
      params: { category } // Send category as query parameter
    })
    .then(resp => {
      setTrips(prevState => ({ ...prevState, results: resp.data, loading: false, err: null }));
    })
    .catch(() => {
      setTrips(prevState => ({ ...prevState, loading: false, results: [], err: null }));
    });
  }, [trips.reload]);

  // Calculate the indices for slicing the trips array
  const indexOfLastTrip = currentPage * tripsPerPage;
  const indexOfFirstTrip = indexOfLastTrip - tripsPerPage;
  const currentTrips = trips.results.slice(indexOfFirstTrip, indexOfLastTrip);

  // Calculate the total number of pages
  const totalPages = Math.ceil(trips.results.length / tripsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container className="mt-5" style={{ marginTop: '50px' }}>
      {trips.loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner">&#9765;</div>
            <span>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {currentTrips.length > 0 ? (
            <>
              <Card className="p-4" style={{ marginTop: '50px' }}>
                <Card.Body>
                  <h2 className="text-center mb-4">Booking Trips</h2>
                  <Row>
                    {currentTrips.map((trip) => (
                      <Col md={4} key={trip.id} className="mb-4">
                        <Card className="h-100 trip-card">
                          <Card.Img variant="top" src={`https://api.goldenpharaohtours.com/uploads/${trip.img}`} alt={trip.name} />
                          <Card.Body>
                            <Card.Title style={{ color: 'orange' }}>{trip.name}</Card.Title>
                            <Card.Text className="trip-description">
                              {trip.description}
                            </Card.Text>
                            <Link to={`/TripsDetails/${trip.id}`}>
                              <Button variant="warning">
                                {t('Trips Details')}
                              </Button>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
              <Pagination className="mt-4 justify-content-center">
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          ) : (
            <Alert variant='danger' style={{ marginTop: "150px" }}>
              No Trips Available, Try Again Later
            </Alert>
          )}
        </>
      )}
    </Container>
  );
};

export default TripsCards;
