import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';



/// src/i18n.js

const resources = {
  en: {
    translation: {
      "Our Destinations": "Our Destinations",
      "Choose your next destination": "Choose your next destination",
      "Your next trip": "Your next trip",
      "Why Choose Us?": "Why Choose Us?",
      "Expert Guides": "Expert Guides: Our knowledgeable and friendly guides are passionate about the history and culture of Egypt. They are committed to providing informative and engaging tours that bring the past to life.",
      "Personalized Service": "Personalized Service: ",
      "Our Story": "Our Story",
      "Founded in 2024": "Founded in 2024, magistic was born out of a passion for showcasing the grandeur of Egypt to the world. With years of experience in the tourism industry, our team of experts combines local knowledge with a global perspective to create tours that are both authentic and immersive.",
      "What We Offer": "What We Offer",
      "Historical Tours": "Historical Tours: Explore the ancient wonders of Egypt, from the majestic Pyramids of Giza and the Sphinx to the temples of Luxor and Karnak. Walk through the Valley of the Kings and discover the treasures of Tutankhamun.",
      "Adventure Tours": "Adventure Tours: ",
      "Immerse yourself in thrilling adventures with our desert safaris, Nile cruises, and snorkeling or diving excursions in the Red Sea.":"Immerse yourself in thrilling adventures with our desert safaris, Nile cruises, and snorkeling or diving excursions in the Red Sea.",
      "Cultural Experiences": "Cultural Experiences: ",
      "Dive into the vibrant culture of Egypt. Enjoy traditional music and dance performances, visit bustling bazaars, and savor the flavors of Egyptian cuisine.":"Dive into the vibrant culture of Egypt. Enjoy traditional music and dance performances, visit bustling bazaars, and savor the flavors of Egyptian cuisine.",
      "About Us": "About Us",
      "Discover the Wonders of Egypt": "Discover the Wonders of Egypt with magistic",
      "Book Now": "Book Now",
      "Every Moment Counts": "Every Moment Counts",
      "Top Trips": "Top Trips",
      "No trips available": "No trips available, please try again later",
      "Package Categories": "Package Categories",
      "Historical": "Historical ",
      "Dive into history with our detailed and engaging historical tours.":"Dive into history with our detailed and engaging historical tours.",
      "View Packages": "View Packages",
      "Adventure": "Adventure ",
      "Embark on exciting adventures with our desert safaris and more.":"Embark on exciting adventures with our desert safaris and more.",
      "Cultural": "Cultural ",
      "Experience the rich culture of Egypt with our immersive cultural packages.":"Experience the rich culture of Egypt with our immersive cultural packages.",
      "How It Works": "How It Works",
      "Step 1": "Step 1",
      "Choose your destination": "Choose your destination",
      "Step 2": "Step 2",
      "Book your trip": "Book your trip",
      "Step 3": "Step 3",
      "Pack your bags": "Pack your bags",
      "Step 4": "Step 4",
      "Enjoy your trip": "Enjoy your trip",
      "Trip Details": "Trip Details",
      "Payment Details": "Payment Details",
      "Name": "Name",
      "Enter your name": "Enter your name",
      "Email": "Email",
      "Enter your email": "Enter your email",
      "Nationality": "Nationality",
      "Select your nationality": "Select your nationality",
      "Mobile": "Mobile",
      "Enter your mobile number": "Enter your mobile number",
      "From": "From",
      "To": "To",
      "Number of Adults": "Number of Adults",
      "Number of Children": "Number of Children",
      "Number of Packages": "Number of Packages",
      "Confirm": "Confirm",
      "Overview": "Overview",
      "Duration": "Duration",
      "Countries": "Countries",
      "Type": "Type",
      "Run": "Run",
      "Included": "Included",
      "Itinerary": "Itinerary",
      "Prices & Accommodation": "Prices & Accommodation",
      "Price": "Price",
      "Standard Price": "Standard Price",
      "Per Person for 3 to 4 People": "Per Person for 3 to 4 People",
      "Per Person in Double Room": "Per Person in Double Room",
      "Premium Price": "Premium Price",
      "Offers": "Offers",
      "Offer Name": "Offer Name",
      "View More Images": "View More Images",
      "All Images": "All Images",
      "Loading...": "Loading...",
      "Error": "Error",
      "No Trip Details Available": "No Trip Details Available",
      "home": "Home",
      "about_us": "About Us",
      "destinations": "Destinations",
      "all": "All",
      "egypt": "Egypt",
      "austria": "Austria",
      "paris": "Paris",
      "language": "Language",
      "english": "English",
      "spanish": "Español",
      "categories": "Categories",
      "full_day": "Full Day",
      "half_day": "Half Day",
      "night_tours": "Night Tours",
      "packages": "Packages",
      "special_offers": "Special Offers",
      "vip": "VIP",
      "manage_trips": "Manage Trips",
      "manage_bookings": "Manage Bookings",
      "search": "Search",
      "newsletter": "Newsletter",
      "description": "Subscribe to our golden pharaoh’s tours for weekly updates and offers.",
      "your email address": "Your email address",
      "subscribe": "Subscribe",
      'Product':'Product',
      'features':'features',
      'testimonials':'testimonials',
      'highlights':'highlights',
      'pricing':'pricing',
      'faqs':'faqs',
      'Company':'Company',
      'about':'about',
      'careers':'careers',
      'press':'press',
      'Legal':'Legal',
      'terms':'terms',
      'privacy':'privacy',
      'contact':'contact',
      'privacy':'privacy',
      'terms':'terms',
      'Trips Details':'Trips Details',

      'Detalles del Pago':'Detalles del Pago',
      'Name':'Name',
      'Enter your name':'Enter your name',
      'Email':'Email',
      'Enter your email':'Enter your email',
      'Nationality':'Nationality',
      'Select your nationality':'Select your nationality',
      'Mobile':'Mobile',
      'Enter your mobile number':'Enter your mobile number',
      'From':'From',
      'mm/dd/yyyy"':'mm/dd/yyyy"',
      'To':'To',
      'Number of Adults':'Number of Adults',
      'Number of Children':'Number of Children',
      'Number of Packages':'Number of Packages',
      'Note':'Note',
      'Enter your note':'Enter your note',
      "Overview": "Overview",
  "Duration": "Duration",
  "Countries": "Countries",
  "Egypt": "Egypt",
  "Type": "Type",
  "Cultural": "Cultural",
  "Run": "Run",
  "Weekly": "Weekly",
  "Description": "Description",
  "Included": "Included",
  "Transportation": "Transportation",
  "Nile cruise": "Nile cruise",
  "entry fees tickets": "entry fees tickets",
  "tipping": "tipping",
  "lunch": "lunch",
  "Itinerary": "Itinerary",
  "day": "day",
  "Prices & Accommodation": "Prices & Accommodation",
  "Price": "Price",
  "Standard Price": "Standard Price",
   "Premium Price": "Premium Price",
  "Offers": "Offers",
  "Offer Name": "Offer Name",
  "offer description": "offer description",
  'What is our Business ?':'What is our Business ?',
  'Welcome to our golden pharaoh’s tours tourism company! We are dedicated to providing unforgettable travel experiences and creating lifelong memories for our valued customers. With a passion for exploration and a commitment to exceptional service, we strive to be your trusted partner in all your travel adventures.':'Welcome to our golden pharaoh’s tours tourism company! We are dedicated to providing unforgettable travel experiences and creating lifelong memories for our valued customers. With a passion for exploration and a commitment to exceptional service, we strive to be your trusted partner in all your travel adventures.',
  'About Us':'About Us',
  'We carefully select accommodations, tour operators, and transportation partners that share our values of sustainability and responsible tourism. By choosing us, you can be confident that your travels are contributing to the preservation of our planet and the well-being of local communities.':'We carefully select accommodations, tour operators, and transportation partners that share our values of sustainability and responsible tourism. By choosing us, you can be confident that your travels are contributing to the preservation of our planet and the well-being of local communities.',
  'Experiential Travel':'Experiential Travel',
  "Our new strategy focuses on curating unique and immersive experiences that go beyond the surface. Whether it's learning traditional cooking techniques from local chefs, participating in conservation projects, or engaging in cultural exchanges, we will design itineraries that allow you to create meaningful connections and lasting memories.":"Our new strategy focuses on curating unique and immersive experiences that go beyond the surface. Whether it's learning traditional cooking techniques from local chefs, participating in conservation projects, or engaging in cultural exchanges, we will design itineraries that allow you to create meaningful connections and lasting memories.",
  'Community Engagement':'Community Engagement',
  "We recognize the importance of engaging with local communities and empowering them through tourism. Our new strategy emphasizes collaboration with local stakeholders to ensure that our activities benefit the communities we visit..":"We recognize the importance of engaging with local communities and empowering them through tourism. Our new strategy emphasizes collaboration with local stakeholders to ensure that our activities benefit the communities we visit..",
"Not to be in a way that dictates discomfort, but rather at the large placement of the group, aligning adjustments as one progresses forward.":"Not to be in a way that dictates discomfort, but rather at the large placement of the group, aligning adjustments as one progresses forward.",
    }
  },
  es: {
    translation: {
      "Our Destinations": "Nuestros Destinos",
      "Choose Your Next Destination": "Elige tu próximo destino",
      "Your next trip": "Tu próximo viaje",
      "Why Choose Us?": "Por Qué Elegirnos?",
      "Expert Guides": "Guías Expertos: Nuestros guías conocedores y amigables están apasionados por la historia y cultura de Egipto. Se comprometen a proporcionar recorridos informativos y atractivos que traigan el pasado a la vida.",
      "Personalized Service": "Servicio Personalizado: Nos enorgullece ofrecer un servicio personalizado a cada cliente. Desde el momento en que reservas con nosotros hasta que tu viaje termina, nos aseguramos de que cada detalle esté cuidado.",
      "Our Story": "Nuestra Historia",
      "Founded in 2024": "Fundada en 2024, magistic nació de la pasión por mostrar la grandeza de Egipto al mundo. Con años de experiencia en la industria del turismo, nuestro equipo de expertos combina el conocimiento local con una perspectiva global para crear recorridos que son tanto auténticos como inmersivos.",
      "What We Offer": "Lo Que Ofrecemos",
      "Historical Tours": "Tours Históricos: Explora las maravillas antiguas de Egipto, desde las majestuosas Pirámides de Giza y la Esfinge hasta los templos de Luxor y Karnak. Camina por el Valle de los Reyes y descubre los tesoros de Tutankamón.",
      "Adventure Tours": "Tours de Aventura: Sumérgete en emocionantes aventuras con nuestros safaris en el desierto, cruceros por el Nilo y excursiones de snorkel o buceo en el Mar Rojo.",
      "Cultural Experiences": "Experiencias Culturales: Sumérgete en la vibrante cultura de Egipto. Disfruta de actuaciones de música y danza tradicionales, visita bazares bulliciosos y saborea los sabores de la cocina egipcia.",
      "About Us": "Sobre Nosotros",
      "Discover the Wonders of Egypt": "Descubre las Maravillas de Egipto con magistic",
      "Book Now": "Reservar Ahora",
      "Every Moment Counts": "Cada Momento Perdido",
      "Top Trips": "Los Mejores Viajes",
      "No trips available": "No hay viajes disponibles, intenta nuevamente más tarde",
      "Package Categories": "Categorías de Paquetes",
      "Historical": "Histórico: ",
      "Dive deep into history with our detailed and engaging historical tours.":"Sumérgete en la historia con nuestros recorridos históricos detallados y atractivos.",
      "View Packages": "Ver Paquetes",
      "Adventure": "Aventura: ",
      "Embark on exciting adventures with our desert safaris and more.":"Embarca en emocionantes aventuras con nuestros safaris en el desierto y más.",
      "Cultural": "Cultural",
      "Experience the rich culture of Egypt with our immersive cultural packages.":"Experimenta la rica cultura de Egipto con nuestros paquetes culturales inmersivos.",
      "How It Works": "Cómo Funciona",
      "Step 1": "Paso 1",
      "Choose your destination": "Elige tu destino",
      "Step 2": "Paso 2",
      "Book your trip": "Reserva tu viaje",
      "Step 3": "Paso 3",
      "Pack your bags": "Empaca tus maletas",
      "Step 4": "Paso 4",
      "Enjoy your trip": "Disfruta tu viaje",
      "Trip Details": "Detalles del Viaje",
      "Payment Details": "Detalles del Pago",
      "Name": "Nombre",
      "Enter your name": "Ingresa tu nombre",
      "Email": "Correo Electrónico",
      "Enter your email": "Ingresa tu correo electrónico",
      "Nationality": "Nacionalidad",
      "Select your nationality": "Selecciona tu nacionalidad",
      "Mobile": "Teléfono Móvil",
      "Enter your mobile number": "Ingresa tu número móvil",
      "From": "Desde",
      "To": "Hasta",
      "Number of Adults": "Número de Adultos",
      "Number of Children": "Número de Niños",
      "Number of Packages": "Número de Paquetes",
      "Confirm": "Confirmar",
      "Overview": "Descripción General",
      "Duration": "Duración",
      "Countries": "Países",
      "Type": "Tipo",
      "Run": "Ejecutar",
      "Included": "Incluido",
      "Itinerary": "Itinerario",
      "Prices & Accommodation": "Precios y Alojamiento",
      "Price": "Precio",
      "Standard Price": "Precio Estándar",
      "Per Person for 3 to 4 People": "Por Persona para 3 a 4 Personas",
      "Per Person in Double Room": "Por Persona en Habitación Doble",
      "Premium Price": "Precio Premium",
      "Offers": "Ofertas",
      "Offer Name": "Nombre de la Oferta",
      "View More Images": "Ver Más Imágenes",
      "All Images": "Todas las Imágenes",
      "Loading...": "Cargando...",
      "Error": "Error",
      "No Trip Details Available": "No hay detalles del viaje disponibles",
      "home": "Inicio",
  "about_us": "Sobre Nosotros",
  "destinations": "Destinos",
  "all": "Todo",
  "egypt": "Egipto",
  "austria": "Austria",
  "paris": "París",
  "language": "Idioma",
  "english": "Inglés",
  "spanish": "Español",
  "categories": "Categorías",
  "full_day": "Todo el día",
  "half_day": "Medio día",
  "night_tours": "Tours nocturnos",
  "packages": "Paquetes",
  "special_offers": "Ofertas especiales",
  "vip": "VIP",
  "manage_trips": "Gestionar viajes",
  "manage_bookings": "Gestionar reservas",
  "subscribe": "Buscar",
  "buscar": "Buscar",
  "About Us": "Sobre Nosotros",
  "Discover the Wonders of Egypt with golden pharaoh’s tours": "Descubre las maravillas de Egipto con los tours del faraón dorado",
  "Welcome to golden pharaoh’s tours, where your journey through the timeless beauty and rich history of Egypt begins. As a premier tourism company based in Egypt, we are dedicated to providing unforgettable travel experiences that cater to all types of travelers. Whether you're an adventurer, a history enthusiast, or someone looking to relax and unwind, we have the perfect itinerary for you.": "Bienvenidos a los tours del faraón dorado, donde comienza tu viaje a través de la belleza intemporal y la rica historia de Egipto. Como una compañía de turismo de primer nivel con sede en Egipto, estamos dedicados a ofrecer experiencias de viaje inolvidables que se adaptan a todo tipo de viajeros. Ya seas un aventurero, un entusiasta de la historia o alguien que busque relajarse y descansar, tenemos el itinerario perfecto para ti.",
  "Book Now": "Reservar Ahora",
  "ABOUT US": "SOBRE NOSOTROS",
  "Why Choose Us?": "¿Por qué elegirnos?",
  "Expert Guides": "Guías Expertos",
  "Personalized Service": "Servicio Personalizado",
  "We pride ourselves on offering personalized service to every client. From the moment you book with us until your journey ends, we ensure that every detail is taken care of.": "Nos enorgullece ofrecer un servicio personalizado a cada cliente. Desde el momento en que reservas con nosotros hasta que tu viaje termina, nos aseguramos de que cada detalle sea atendido.",
  "Our Story": "Nuestra Historia",
  "Founded in 2024, golden pharaoh’s tours was born out of a passion for showcasing the splendor of Egypt to the world. With years of experience in the travel and tourism industry, our team of experts combines local knowledge with a global perspective to create tours that are both authentic and immersive.": "Fundada en 2024, los tours del faraón dorado nacieron de una pasión por mostrar el esplendor de Egipto al mundo. Con años de experiencia en la industria de viajes y turismo, nuestro equipo de expertos combina el conocimiento local con una perspectiva global para crear tours que son tanto auténticos como inmersivos.",
  "What We Offer": "Lo Que Ofrecemos",
  "Historical Tours: Explore the ancient wonders of Egypt, from the majestic Pyramids of Giza and the Sphinx to the temples of Luxor and Karnak. Walk through the Valley of the Kings and discover the treasures of Tutankhamun.": "Tours Históricos: Explora las maravillas antiguas de Egipto, desde las majestuosas Pirámides de Giza y la Esfinge hasta los templos de Luxor y Karnak. Pasea por el Valle de los Reyes y descubre los tesoros de Tutankamón.",
  "Adventure Tours": "Tours de Aventura",
  "Dive into thrilling adventures with our desert safaris, Nile River cruises, and snorkeling or diving trips in the Red Sea.": "Sumérgete en emocionantes aventuras con nuestros safaris por el desierto, cruceros por el río Nilo y viajes de esnórquel o buceo en el Mar Rojo.",
  "Cultural Experiences": "Experiencias Culturales",
  "Immerse yourself in the vibrant culture of Egypt. Enjoy traditional music and dance performances, visit bustling bazaars, and savor the flavors of Egyptian cuisine.": "Sumérgete en la vibrante cultura de Egipto. Disfruta de espectáculos de música y danza tradicional, visita bazares bulliciosos y saborea los sabores de la cocina egipcia.",
  "search": "Buscar",
      "description": "Suscríbete a los tours del faraón dorado para recibir actualizaciones y ofertas semanales.",
      "your email address": "Tu dirección de correo electrónico",
      "subscribe": "Suscribirse",
      'Product':'Producto',
      'features':'Características',
      'testimonials':'Testimonios',
      'highlights':'Destacados',
      'pricing':'Precios',
      'faqs':'Preguntas frecuentes',
      'Company':'Compañía',
      'about':'Acerca de',
      'careers':'Carreras" or "Empleos',
      'press':'Prensa',
      'Legal':'Legal',
      'terms':'Términos',
      'privacy':'Privacidad',
      'contact':'Contacto',
      'privacy':'Privacidad',
      'terms':'Términos',
      'Trips Details':'Detalles de los viajes',

      "Detalles del Pago": "Detalles del Pago",
  "Name": "Nombre",
  "Enter your name": "Ingresa tu nombre",
  "Email": "Correo Electrónico",
  "Enter your email": "Ingresa tu correo electrónico",
  "Nationality": "Nacionalidad",
  "Select your nationality": "Selecciona tu nacionalidad",
  "Mobile": "Teléfono Móvil",
  "Enter your mobile number": "Ingresa tu número de móvil",
  "From": "Desde",
  "mm/dd/yyyy": "mm/dd/aaaa",
  "To": "Hasta",
  "Number of Adults": "Número de Adultos",
  "Number of Children": "Número de Niños",
  "Number of Packages": "Número de Paquetes",
  "Note": "Nota",
  "Enter your note": "Ingresa tu nota",
  "Overview": "Resumen",
  "Duration": "Duración",
  "Days": "Días",
  "Countries": "Países",
  "Egypt": "Egipto",
  "Type": "Tipo",
  "Cultural": "Cultural",
  "Run": "Frecuencia",
  "Weekly": "Semanal",
  "Description": "Descripción",
  "Included": "Incluido",
  "Transportation": "Transporte",
  "Nile cruise": "Crucero por el Nilo",
  "entry fees tickets": "Boletos de entrada",
  "tipping": "Propinas",
  "lunch": "Almuerzo",
  "Itinerary": "Itinerario",
  "day": "día",
  "Prices & Accommodation": "Precios y Alojamiento",
  "Price": "Precio",
  "Standard Price": "Precio Estándar",
  "Premium Price": "Precio Premium",
  "Offers": "Ofertas",
  "Offer Name": "Nombre de la oferta",
  "offer description": "descripción de la oferta",
  'What is our Business ?':'Cuál es nuestro negocio?',
  'Welcome to our golden pharaoh’s tours tourism company! We are dedicated to providing unforgettable travel experiences and creating lifelong memories for our valued customers. With a passion for exploration and a commitment to exceptional service, we strive to be your trusted partner in all your travel adventures.':'Bienvenidos a la compañía de turismo Golden Pharaoh’s Tours! Nos dedicamos a ofrecer experiencias de viaje inolvidables y a crear recuerdos duraderos para nuestros valiosos clientes. Con una pasión por la exploración y un compromiso con un servicio excepcional, nos esforzamos por ser su socio de confianza en todas sus aventuras de viaje.',
  'About Us':'Sobre Nosotros',
  'We carefully select accommodations, tour operators, and transportation partners that share our values of sustainability and responsible tourism. By choosing us, you can be confident that your travels are contributing to the preservation of our planet and the well-being of local communities.':'Seleccionamos cuidadosamente alojamientos, operadores turísticos y socios de transporte que comparten nuestros valores de sostenibilidad y turismo responsable. Al elegirnos, puede estar seguro de que sus viajes están contribuyendo a la preservación de nuestro planeta y al bienestar de las comunidades locales.',
  'Experiential Travel':'Viajes Experienciales',
  "Our new strategy focuses on curating unique and immersive experiences that go beyond the surface. Whether it's learning traditional cooking techniques from local chefs, participating in conservation projects, or engaging in cultural exchanges, we will design itineraries that allow you to create meaningful connections and lasting memories.":"Nuestra nueva estrategia se enfoca en curar experiencias únicas e inmersivas que van más allá de la superficie. Ya sea aprendiendo técnicas tradicionales de cocina con chefs locales, participando en proyectos de conservación o involucrándose en intercambios culturales, diseñaremos itinerarios que le permitan crear conexiones significativas y recuerdos duraderos.",
  'Community Engagement':'Compromiso con la Comunidad',
  "We recognize the importance of engaging with local communities and empowering them through tourism. Our new strategy emphasizes collaboration with local stakeholders to ensure that our activities benefit the communities we visit..":"Reconocemos la importancia de involucrarse con las comunidades locales y empoderarlas a través del turismo. Nuestra nueva estrategia enfatiza la colaboración con las partes interesadas locales para garantizar que nuestras actividades beneficien a las comunidades que visitamos.",
"Not to be in a way that dictates discomfort, but rather at the large placement of the group, aligning adjustments as one progresses forward.":"Neque ut et velit dictum molestie at pellentesque aliquet magna ut tincidunt ullamcorper mauris mi et",
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // React already safes from xss
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
