import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Avatar from 'react-avatar';
import './application.css';
import { FaEdit } from 'react-icons/fa';

const Application = () => {
  const location = useLocation();
  const bookingData = location.state;

  return (
    <Container className="mt-5 profile-card-container" style={{marginTop:'100px'}}>
      <Row>
        <Col className="text-center">
          <Avatar name={bookingData.name} size="150" round={true} style={{marginTop:'50px'}} />
          <h2>{bookingData.name || 'Name'}</h2>
          <p className="username">@{bookingData.name.toLowerCase() || 'username'} <FaEdit className="edit-icon" /></p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="offset-md-3">
          <Card className="profile-card">
            <Card.Body>
              <Card.Text><strong>Email:</strong> {bookingData.email} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Price:</strong> {bookingData.price} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Mobile:</strong> {bookingData.mobile} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>From:</strong> {bookingData.fromm} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>To:</strong> {bookingData.too} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Nationality:</strong> {bookingData.nationality} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Number of Adults:</strong> {bookingData.numberofadults} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Number of Children:</strong> {bookingData.nnumberofchilds} <FaEdit className="edit-icon" /></Card.Text>
              <Card.Text><strong>Note:</strong> {bookingData.note} <FaEdit className="edit-icon" /></Card.Text>
              <div className="text-center mt-3">
                <Link to={'/'}><Button variant="primary">Go Home</Button></Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Application;
