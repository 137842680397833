import { useEffect } from 'react';

const UsePageSEO = ({
    title,
    description,
    keywords = [],
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
}) => {
    useEffect(() => {
        const previousTitle = document.title;
        const previousMetaTags = {};

        // Store previous meta tag values before setting new ones
        previousMetaTags['description'] = getMetaTagContent('name', 'description');
        previousMetaTags['keywords'] = getMetaTagContent('name', 'keywords');
        previousMetaTags['og:title'] = getMetaTagContent('property', 'og:title');
        previousMetaTags['og:description'] = getMetaTagContent('property', 'og:description');
        previousMetaTags['og:image'] = getMetaTagContent('property', 'og:image');
        previousMetaTags['og:url'] = getMetaTagContent('property', 'og:url');

        document.title = title;

        setMetaTag('name', 'description', description);

        // Ensure keywords is an array before joining
        const keywordsContent = Array.isArray(keywords) ? keywords.join(', ') : keywords;
        setMetaTag('name', 'keywords', keywordsContent);

        setMetaTag('property', 'og:title', ogTitle || title);
        setMetaTag('property', 'og:description', ogDescription || description);
        setMetaTag('property', 'og:image', ogImage);
        setMetaTag('property', 'og:url', ogUrl || window.location.href);

        return () => {
            // Restore previous title and meta tags
            document.title = previousTitle;

            setMetaTag('name', 'description', previousMetaTags['description']);
            setMetaTag('name', 'keywords', previousMetaTags['keywords']);
            setMetaTag('property', 'og:title', previousMetaTags['og:title']);
            setMetaTag('property', 'og:description', previousMetaTags['og:description']);
            setMetaTag('property', 'og:image', previousMetaTags['og:image']);
            setMetaTag('property', 'og:url', previousMetaTags['og:url']);
        };
    }, [
        title,
        description,
        keywords,
        ogTitle,
        ogDescription,
        ogImage,
        ogUrl,
    ]);

    const setMetaTag = (attr, key, content) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    };

    const getMetaTagContent = (attr, key) => {
        const element = document.querySelector(`meta[${attr}="${key}"]`);
        return element ? element.getAttribute('content') : null;
    };
};

export default UsePageSEO;
