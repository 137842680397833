import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form, FormControl } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import logor from '../Images/logoR.png'

function Copyright() {
  const { t } = useTranslation();
  return (
    <p className="text-secondary mt-3">
      {'Copyright © '}
      <a href="https://mui.com/" className="text-secondary" >golden pharaoh’s tours&nbsp;</a>
      {new Date().getFullYear()} {t('copyright')}
    </p>
    
  );
}

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Container fluid className="bg-light py-5">
      <Row className="justify-content-center mb-4">
        <Col xs={12} sm={6} md={4} className="text-center">
          <h6 className="font-weight-bold mt-3" style={{color:'orange'}}>{t('golden pharaoh’s tours')}</h6>
          <p className="text-secondary mb-3">
            {t('description')}
          </p>
          <Form inline className="d-flex justify-content-center">
            <FormControl
              type="email"
              placeholder={t('Email')}
              className="mr-2"
            />
            <Button variant="warning">{t('subscribe')}</Button>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center mb-4">
        <Col xs={6} sm={3} md={2}>
          <h6 className="font-weight-bold">{t('category')}</h6>
          <ul className="list-unstyled">
            <li><a href="/Tripscards?category=FullDay" className="text-secondary">{t('full_day')}</a></li>
            <li><a href="/Tripscards?category=HalfDay" className="text-secondary">{t('half_day')}</a></li>
            <li><a href="/Tripscards?category=Nighttours" className="text-secondary">{t('night_tours')}</a></li>
            <li><a href="/Tripscards?category=Packages" className="text-secondary">{t('packages')}</a></li>
            <li><a href="/Tripscards?category=VIP" className="text-secondary">{t('vip')}</a></li>
          </ul>
        </Col>
        <Col xs={6} sm={3} md={2}>
          <h6 className="font-weight-bold">{t('pages')}</h6>
          <ul className="list-unstyled">
            <li><a href="/about" className="text-secondary">{t('About')}</a></li>
            <li><a href="/Reviews" className="text-secondary">{t('Reviews')}</a></li>
            <li><a href="/Tripscards?category=SpecialOffers" className="text-secondary">{t('special_offers')}</a></li>
          </ul>
        </Col>
        <Col xs={6} sm={3} md={2}>
          <h6 className="font-weight-bold">{t('About-us')}</h6>
          <ul className="list-unstyled">
            <li><a href="https://facebook.com/" className="text-secondary">{t('facebook')}</a></li>
            <li><a href="https://wa.me/2001503331523" className="text-secondary">{t('call-us')}</a></li>
            <li><a href="https://goldenpharaohstours@gmail.com" className="text-secondary">{t('contact')}</a></li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-between align-items-center pt-4 border-top">
        <h3>POWERED BY</h3>
        {/* <Col xs={12} md={6} className="text-md-right">
          <a href="https://github.com/mui" className="text-secondary mr-2" aria-label="GitHub" ><FaFacebook style={{color:'blue'}}/></a>
          <a href="https://x.com/MaterialUI" className="text-secondary mr-2" aria-label="Twitter" ><FaTwitter style={{color:'skyblue'}}/></a>
          <a href="https://www.linkedin.com/company/mui/" className="text-secondary" aria-label="LinkedIn" ><FaLinkedin style={{color:'deepskyblue'}} /></a>
        </Col> */}
        <a href="https://www.facebook.com/profile.php?id=61563638756668&mibextid=LQQJ4d " style={{marginTop:'-20px'}}><img src={logor} alt="Logo" style={{ height: '180px',width:'200px'}}  /></a>
      </Row>
    </Container>
  );
}
