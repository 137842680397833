import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { FaStar, FaStarHalfAlt, FaGoogle, FaTripadvisor, FaFacebook } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import './Reviews.css';  // Adjust the path as needed
import { Link, useLocation } from 'react-router-dom';
import {getAuthUser} from '../../Helper/Storage';


const Reviews = ({ limit }) => {
  const Auth = getAuthUser();
  const [reviews, setReviews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newReview, setNewReview] = useState({ name: '', text: '', rating: 0, photo: '', tripName: '' });
  const [hoverRating, setHoverRating] = useState(0);
  const [formError, setFormError] = useState('');
  const location = useLocation();
  const [destinations, setDestinations] = useState([]);

  const trips = ["Trip to the Sphinx", "Trip to the Pyramids", "Trip to the Nile", "Trip to Luxor"];
  let category='';
  useEffect(() => {
    const fetchDestinations = async () => {
        try {
            const response =  await axios.get("https://api.goldenpharaohtours.com/tripsgetall", {
              params: { category } // Send category as query parameter
            })
            setDestinations(response.data);
        } catch (error) {
            console.error("Failed to fetch destinations", error);
        }
    };

    fetchDestinations();
}, []);
  useEffect(() => {
    const endpoint = limit === 4 ? 'https://api.goldenpharaohtours.com/gettopreviews' : 'https://api.goldenpharaohtours.com/getallreviews';
    axios.get(endpoint)
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the reviews!", error);
      });
  }, [limit]);

  const renderRating = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} />);
    }

    if (halfStar) {
      stars.push(<FaStarHalfAlt key="half" />);
    }

    return stars;
  };

  const handleShowModal = () => {
    setFormError('');
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prevReview) => ({ ...prevReview, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewReview((prevReview) => ({ ...prevReview, photo: file }));
    };
    reader.readAsDataURL(file);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (newReview.rating === 0) {
      setFormError('Please select a rating.');
      return;
    }

    // Create FormData object
    const formData = new FormData();
    formData.append('name', newReview.name);
    formData.append('tripname', newReview.tripName);
    formData.append('rate', newReview.rating);
    formData.append('comment', newReview.text);
    if (newReview.photo) {
      formData.append('img', newReview.photo); // Append the photo as 'img'
    }

    // Send POST request
    axios.post('https://api.goldenpharaohtours.com/submitreview', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setReviews([...reviews, newReview]);
        setNewReview({ name: '', text: '', rating: 0, photo: '', tripName: '' });
        handleCloseModal();
      })
      .catch(error => {
        console.error("There was an error submitting the review!", error);
        if (error.response && error.response.data) {
          setFormError(error.response.data.error || 'An error occurred');
        } else {
          setFormError('An error occurred');
        }
      });
  };

  const handleStarClick = (index) => {
    setNewReview((prevReview) => ({ ...prevReview, rating: index }));
  };

  const handleStarMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleStarMouseLeave = () => {
    setHoverRating(0);
  };

  const handleDeleteReview = (reviewId) => {
    // Send DELETE request to the backend
    axios.delete(`https://api.goldenpharaohtours.com/deletereview/${reviewId}`)
      .then(response => {
        setReviews(reviews.filter(review => review.id !== reviewId));
      })
      .catch(error => {
        console.error("There was an error deleting the review!", error);
      });
  };

  const displayedReviews = limit === 4 ? reviews.slice(0, limit) : reviews;

  return (
    <div className="reviews-section">
      <Container className="my-5">
        <Row className="justify-content">
          <Col lg={8}>
            <h2 style={{color:'orange'}}>What Our Guests Say</h2>
            <p>Not to be in a way that dictates discomfort, but rather at the large placement of the group, aligning adjustments as one progresses forward.</p>
          </Col>
          <Col lg={4} className="text-center">
            <h2>4.6</h2>
            <p>Out of 5</p>
            <div className="rating" style={{color:'gold'}}>{renderRating(4.6)}</div>
            <div className="mt-2">
              <div style={{color:'red'}}><FaGoogle /> 1920 Ratings</div>
              <div><FaTripadvisor /> 960 Ratings</div>
              <div style={{color:'blue'}}><FaFacebook /> 1024 Likes</div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          {displayedReviews.map((review, index) => (
            <Col lg={6} key={index}>
              <Card className="review-card" >
                <div className="trip-name">
                  <h3 >{"Went To "+review.tripname}</h3>
                  {Auth && Auth[0].status === 1 && (
                <>
                  <AiOutlineClose 
                    className="close-button" 
                    onClick={() => handleDeleteReview(review.id)} 
                  />
                  </>
                  )}
                </div>
                <Card.Body>
                  <Card.Text className="review-text">"{review.comment}"</Card.Text>
                  <div className="reviewer-info">
                  <img src={`https://api.goldenpharaohtours.com/uploads/${review.pic}`} alt={review.name} className="reviewer-photo"  />
                  <div className="rating">{renderRating(review.rate)}</div>
                  </div>
                  <div><span className="reviewer-name">{review.name}</span></div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {location.pathname === '/' && (
            <Col lg={12} className="text-right mt-4">
              <Link to="/Reviews">
                <h6>show more</h6>
              </Link>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Button variant="warning" onClick={handleShowModal}>Add Your Review</Button>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Your Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            {formError && <p className="text-danger">{formError}</p>}
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="text" 
                name="name" 
                value={newReview.name} 
                onChange={handleInputChange} 
                required 
                className="custom-form-control"
              />
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label>Review</Form.Label>
              <Form.Control 
                as="textarea" 
                name="text" 
                value={newReview.text} 
                onChange={handleInputChange} 
                required 
                className="custom-form-control hover-blue" 
              />
            </Form.Group>
            <Form.Group controlId="formRating">
              <Form.Label>Rating</Form.Label>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <FaStar
                    key={index}
                    className={`star ${index <= (hoverRating || newReview.rating) ? 'filled' : ''}`}
                    onClick={() => handleStarClick(index)}
                    onMouseEnter={() => handleStarMouseEnter(index)}
                    onMouseLeave={handleStarMouseLeave}
                  />
                ))}
              </div>
            </Form.Group>
            <Form.Group controlId="formPhoto">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control 
                type="file" 
                name="photo" 
                onChange={handleImageUpload} 
                className="custom-form-control"
              />
              {newReview.photo && (
                <div className="mt-3">
                  <img src={newReview.photo} alt="Review" style={{ maxWidth: '100%' }} />
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formTripName">
  <Form.Label>Destination</Form.Label>
  <Form.Control 
    as="select" 
    name="tripName" 
    value={newReview.tripName} 
    onChange={handleInputChange} 
    required 
    className="custom-form-control"
  >
    <option value="">Select Destination</option>
    {destinations.map((des, index) => (
      <option key={index} value={des.name}>{des.name}</option>  // Ensure the key is correct
    ))}
  </Form.Control>
</Form.Group>

            <Button variant="warning" type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Reviews;
