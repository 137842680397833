import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal, Alert, Carousel } from 'react-bootstrap';
import { FaClock, FaGlobe, FaTags, FaCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import '../trips/tripsdetilse.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import UsePageSEO from '../../hooks/UsePageSEO/index'
let resp;


const nationalityOptions = [
  { value: 'Afghan', label: 'Afghan' },
  { value: 'Albanian', label: 'Albanian' },
  { value: 'Algerian', label: 'Algerian' },
  { value: 'American', label: 'American' },
  { value: 'Andorran', label: 'Andorran' },
  { value: 'Angolan', label: 'Angolan' },
  { value: 'Antiguans', label: 'Antiguans' },
  { value: 'Argentine', label: 'Argentine' },
  { value: 'Armenian', label: 'Armenian' },
  { value: 'Australian', label: 'Australian' },
  { value: 'Austrian', label: 'Austrian' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Bahamian', label: 'Bahamian' },
  { value: 'Bahraini', label: 'Bahraini' },
  { value: 'Bangladeshi', label: 'Bangladeshi' },
  { value: 'Barbadian', label: 'Barbadian' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Belgian', label: 'Belgian' },
  { value: 'Belizean', label: 'Belizean' },
  { value: 'Beninese', label: 'Beninese' },
  { value: 'Bhutanese', label: 'Bhutanese' },
  { value: 'Bolivian', label: 'Bolivian' },
  { value: 'Bosnian', label: 'Bosnian' },
  { value: 'Botswana', label: 'Botswanan' },
  { value: 'Brazilian', label: 'Brazilian' },
  { value: 'Bruneian', label: 'Bruneian' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Burkinabe', label: 'Burkinabe' },
  { value: 'Burundian', label: 'Burundian' },
  { value: 'Cabo Verdean', label: 'Cabo Verdean' },
  { value: 'Cambodian', label: 'Cambodian' },
  { value: 'Cameroonian', label: 'Cameroonian' },
  { value: 'Canadian', label: 'Canadian' },
  { value: 'Central African', label: 'Central African' },
  { value: 'Chadian', label: 'Chadian' },
  { value: 'Chilean', label: 'Chilean' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Colombian', label: 'Colombian' },
  { value: 'Comorian', label: 'Comorian' },
  { value: 'Congolese', label: 'Congolese' },
  { value: 'Costa Rican', label: 'Costa Rican' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Cuban', label: 'Cuban' },
  { value: 'Cypriot', label: 'Cypriot' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Danish', label: 'Danish' },
  { value: 'Djiboutian', label: 'Djiboutian' },
  { value: 'Dominican', label: 'Dominican' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'East Timorese', label: 'East Timorese' },
  { value: 'Ecuadorean', label: 'Ecuadorean' },
  { value: 'Egyptian', label: 'Egyptian' },
  { value: 'Salvadoran', label: 'Salvadoran' },
  { value: 'Equatorial Guinean', label: 'Equatorial Guinean' },
  { value: 'Eritrean', label: 'Eritrean' },
  { value: 'Estonian', label: 'Estonian' },
  { value: 'Eswatini', label: 'Eswatini' },
  { value: 'Ethiopian', label: 'Ethiopian' },
  { value: 'Fijian', label: 'Fijian' },
  { value: 'Filipino', label: 'Filipino' },
  { value: 'Finnish', label: 'Finnish' },
  { value: 'French', label: 'French' },
  { value: 'Gabonese', label: 'Gabonese' },
  { value: 'Gambian', label: 'Gambian' },
  { value: 'Georgian', label: 'Georgian' },
  { value: 'German', label: 'German' },
  { value: 'Ghanaian', label: 'Ghanaian' },
  { value: 'Greecian', label: 'Grecian' },
  { value: 'Grenadian', label: 'Grenadian' },
  { value: 'Guatemalan', label: 'Guatemalan' },
  { value: 'Guinean', label: 'Guinean' },
  { value: 'Guyanese', label: 'Guyanese' },
  { value: 'Haitian', label: 'Haitian' },
  { value: 'Honduran', label: 'Honduran' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Icelandic', label: 'Icelandic' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Iranian', label: 'Iranian' },
  { value: 'Iraqi', label: 'Iraqi' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Israeli', label: 'Israeli' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Jamaican', label: 'Jamaican' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Jordanian', label: 'Jordanian' },
  { value: 'Kazakhstani', label: 'Kazakhstani' },
  { value: 'Kenyan', label: 'Kenyan' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kuwaiti', label: 'Kuwaiti' },
  { value: 'Kyrgyzstani', label: 'Kyrgyzstani' },
  { value: 'Laotian', label: 'Laotian' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lebanese', label: 'Lebanese' },
  { value: 'Liberian', label: 'Liberian' },
  { value: 'Libyan', label: 'Libyan' },
  { value: 'Liechtensteiner', label: 'Liechtensteiner' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Luxembourger', label: 'Luxembourger' },
  { value: 'Madagascan', label: 'Madagascan' },
  { value: 'Malawian', label: 'Malawian' },
  { value: 'Malaysian', label: 'Malaysian' },
  { value: 'Maldivian', label: 'Maldivian' },
  { value: 'Malian', label: 'Malian' },
  { value: 'Malta', label: 'Maltese' },
  { value: 'Marshallese', label: 'Marshallese' },
  { value: 'Mauritanian', label: 'Mauritanian' },
  { value: 'Mauritian', label: 'Mauritian' },
  { value: 'Mexican', label: 'Mexican' },
  { value: 'Micronesian', label: 'Micronesian' },
  { value: 'Moldovan', label: 'Moldovan' },
  { value: 'Monacan', label: 'Monacan' },
  { value: 'Mongolian', label: 'Mongolian' },
  { value: 'Moroccan', label: 'Moroccan' },
  { value: 'Mozambican', label: 'Mozambican' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibian', label: 'Namibian' },
  { value: 'Nauruan', label: 'Nauruan' },
  { value: 'Nepalese', label: 'Nepalese' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'New Zealander', label: 'New Zealander' },
  { value: 'Nigerian', label: 'Nigerian' },
  { value: 'North Korean', label: 'North Korean' },
  { value: 'Northern Mariana Islander', label: 'Northern Mariana Islander' },
  { value: 'Norwegian', label: 'Norwegian' },
  { value: 'Omani', label: 'Omani' },
  { value: 'Pakistani', label: 'Pakistani' },
  { value: 'Palauan', label: 'Palauan' },
  { value: 'Panamanian', label: 'Panamanian' },
  { value: 'Papua New Guinean', label: 'Papua New Guinean' },
  { value: 'Paraguayan', label: 'Paraguayan' },
  { value: 'Peruvian', label: 'Peruvian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Qatari', label: 'Qatari' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Rwandan', label: 'Rwandan' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucian', label: 'Saint Lucian' },
  { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'Samoan', label: 'Samoan' },
  { value: 'San Marinese', label: 'San Marinese' },
  { value: 'Sao Tomean', label: 'Sao Tomean' },
  { value: 'Saudi', label: 'Saudi' },
  { value: 'Senegalese', label: 'Senegalese' },
  { value: 'Serbian', label: 'Serbian' },
  { value: 'Seychellois', label: 'Seychellois' },
  { value: 'Sierra Leonean', label: 'Sierra Leonean' },
  { value: 'Singaporean', label: 'Singaporean' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'Slovenian', label: 'Slovenian' },
  { value: 'Solomon Islander', label: 'Solomon Islander' },
  { value: 'Somali', label: 'Somali' },
  { value: 'South African', label: 'South African' },
  { value: 'South Korean', label: 'South Korean' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Sri Lankan', label: 'Sri Lankan' },
  { value: 'Sudanese', label: 'Sudanese' },
  { value: 'Surinamese', label: 'Surinamese' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Swiss', label: 'Swiss' },
  { value: 'Syrian', label: 'Syrian' },
  { value: 'Taiwanese', label: 'Taiwanese' },
  { value: 'Tajikistani', label: 'Tajikistani' },
  { value: 'Tanzanian', label: 'Tanzanian' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Togolese', label: 'Togolese' },
  { value: 'Tongan', label: 'Tongan' },
  { value: 'Trinidadian', label: 'Trinidadian' },
  { value: 'Tunisian', label: 'Tunisian' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Turkmen', label: 'Turkmen' },
  { value: 'Tuvaluan', label: 'Tuvaluan' },
  { value: 'Ugandan', label: 'Ugandan' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Uruguayan', label: 'Uruguayan' },
  { value: 'Uzbekistani', label: 'Uzbekistani' },
  { value: 'Vanuatu', label: 'Vanuatuan' },
  { value: 'Vatican', label: 'Vatican' },
  { value: 'Venezuelan', label: 'Venezuelan' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Yemeni', label: 'Yemeni' },
  { value: 'Zambian', label: 'Zambian' },
  { value: 'Zimbabwean', label: 'Zimbabwean' },
  { value: 'Other', label: 'Other' },

];





const TripDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(1);
  const [packages, setPackages] = useState(1);
  const [note, setNote] = useState('');
  const [tripData, setTripData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    nationality: '',
    mobile: '',
    fromm: '',
    too: '',
    note: '',
  });
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const YourComponent = ({ id }) => {
    const [tripData, setTripData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  }
  useEffect(() => {
    axios.get(`https://api.goldenpharaohtours.com/gettripdetails/${id}`)
      .then(response => {
        setTripData(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);
  //
    // Calculate title and description based on tripData or provide default values
    const title = tripData?.name || "Default Title";
    const description = tripData?.description || "Default description";

    UsePageSEO({
      title,
      description,
    });



  
  const handleConfirm = () => {
    if (!formData.name || !formData.email || !selectedNationality || !formData.mobile || !formData.fromm || !formData.too) {
      setFormError('Please fill out all required fields.');
      return;
    }
    setFormError(null);

    const bookingDetails = {
      ...formData,
      price: tripData.price1,
      numberofadults: adults,
      nnumberofchilds: children,
      nationality: selectedNationality.value,
      tripid: id,
      note: note,
    };


    axios.post(`https://api.goldenpharaohtours.com/booking/${id}`, bookingDetails)
      .then(response => {
        resp=response;
        setShowConfirmation(true);
      })
      .catch(error => {
        console.error('There was an error making the booking request:', error);
        alert('There was an error confirming your booking. Please try again.');
      });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
    }));

    
  };

  

  const imageStyle = {
    width: '100%', // Full width of the carousel item
    height: '400px', // Fixed height, adjust as necessary
    objectFit: 'cover', // Ensures the image covers the container
  };

  const wrapperStyle = {
    width: '100%', // Full width of the carousel item
    height: '400px', // Fixed height, adjust as necessary
    overflow: 'hidden', // Hides any overflow
    display: 'flex',
    alignItems: 'center', // Centers image vertically
    justifyContent: 'center', // Centers image horizontally
  };
  const handleCloseConfirmation = () => {
  
  
    if(resp.data.booking[0])
    {
    setShowConfirmation(false);
    navigate('/Application', { state: resp.data.booking[0]}); // Pass booking object to /Application
    }
  };

  if (loading) return         <div className="loading-spinner-overlay">
  <div className="loading-spinner-container">
    <div className="loading-spinner">&#9765;</div>
    <span>Loading...</span>
  </div>
</div>;
  if (error) return <Alert variant="danger">Error: {error}</Alert>;
  if (!tripData) return <Alert variant="warning">No Trip Details Available</Alert>;

  return (
    <>
      <Container className="my-5 page-container">
        <h1 className="page-title">{tripData.name}</h1>
        <Row className="mt-4">
          <Col md={6}>
            <Card>
              <Carousel>
  {tripData.images.map((image, idx) => (
    <Carousel.Item key={idx}>
      <div style={wrapperStyle}>
            <img
              style={imageStyle}
              src={`https://api.goldenpharaohtours.com/uploads/${image}`}
              alt={`Trip ${idx}`}
            />
          </div>
    </Carousel.Item>
  ))}
</Carousel>

              {tripData.images.length > 5 && (
                <>
                  <Button variant="link" onClick={handleShowModal} className="view-more-button">
                    View More Images
                  </Button>
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>All Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {tripData.images.map((image, idx) => (
                        <img key={idx} src={`https://api.goldenpharaohtours.com/uploads/${image}`} alt={`Trip ${idx}`} className="modal-image" />
                      ))}
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </Card>
          </Col>
          <Col md={6}>
            <Card className="payment-details-card">
              <Card.Body>
                <Card.Title>{t('Payment Details')}</Card.Title>
                {formError && <Alert variant="danger">{formError}</Alert>}
                <Form>
                  <Form.Group controlId="name">
                    <Form.Label>{t('Name')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter your name")}
                      className="custom-input"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("Enter your email")}
                      className="custom-input"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="nationality">
                    <Form.Label>{t('Nationality')}</Form.Label>
                    <Select
                      options={nationalityOptions}
                      value={selectedNationality}
                      onChange={setSelectedNationality}
                      placeholder={t("Select your nationality")}
                      className="custom-input"
                    />
                  </Form.Group>
                  <Form.Group controlId="mobile">
                    <Form.Label>{t('Mobile')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter your mobile number")}
                      className="custom-input"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="fromm">
                    <Form.Label>{t('From')}</Form.Label>
                    <Form.Control
                      type="date"
                      className="custom-input"
                      value={formData.fromm}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="too">
                    <Form.Label>{t('To')}</Form.Label>
                    <Form.Control
                      type="date"
                      className="custom-input"
                      value={formData.too}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="adults">
                    <Form.Label>{t('Number of Adults')}</Form.Label>
                    <Form.Control
                      type="number"
                      value={adults}
                      onChange={(e) => setAdults(e.target.value)}
                      className="custom-input"
                    />
                  </Form.Group>
                  <Form.Group controlId="children">
                    <Form.Label>{t('Number of Children')}</Form.Label>
                    <Form.Control
                      type="number"
                      value={children}
                      onChange={(e) => setChildren(e.target.value)}
                      className="custom-input"
                    />
                  </Form.Group>
                  <Form.Group controlId="packages">
                    <Form.Label>{t('Number of Packages')}</Form.Label>
                    <Form.Control
                      type="number"
                      value={packages}
                      onChange={(e) => setPackages(e.target.value)}
                      className="custom-input"
                    />
                  </Form.Group>


                  <Form.Group controlId="note">
  <Form.Label>{t('Note')}</Form.Label>
  <Form.Control
    type="text"
    placeholder={t("Enter your note")}
    className="custom-input"
    value={note}
    onChange={(e) => setNote(e.target.value)}
  />
</Form.Group>





                  <Button variant="warning" onClick={handleConfirm} style={{ marginTop: '20px' }}>
                  {t(' Confirm')}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>


      < Row className="mt-4 overview-section">
          <Col>
            <h2 className="overview-title">{t('Overview')}</h2>
            <div className="overview-icons">
              <div className="overview-icon">
                <FaClock size={32} />
                <div className="overview-icon-title">{t('Duration')}</div>
                <div className="overview-icon-subtitle">{tripData.Duration} {t('Days')}</div>
              </div>
              <div className="overview-icon">
                <FaGlobe size={32} />
                <div className="overview-icon-title">{t('Countries')}</div>
                <div className="overview-icon-subtitle">{tripData.Countries}</div>
              </div>
              <div className="overview-icon">
                <FaTags size={32} />
                <div className="overview-icon-title">{t('Type')}</div>
                <div className="overview-icon-subtitle">{tripData.Type}</div>
              </div>
              <div className="overview-icon">
                <FaCalendarAlt size={32} />
                <div className="overview-icon-title">{t('Run')}</div>
                <div className="overview-icon-subtitle">{tripData.Run}</div>
              </div>
            </div>
            <Col>
            <h2 className="overview-title">{t('Description')}</h2>
            <div className="overview-description"> <h3 style={{textAlign:'center'}}>{tripData.description}</h3></div>
          </Col>
          </Col>
        </Row>
      
        <Row className="justify-content-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
  <Col md={5} className="custom-border" style={{ margin: '10px' }}>
    <h3 className="inclusions-title">{t('Included')}</h3>
    <ul className="inclusions-list">
      {tripData.included.split(',').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </Col>
  <Col md={5} className="custom-border" style={{ margin: '10px' }}>
    <h3 className="inclusions-title">{t('Excluded')}</h3>
    <ul className="inclusions-list">
      {tripData.excluded.split(',').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </Col>
  <Col md={10} className="custom-border mx-auto" style={{ marginTop: '10px' }}>
    <div className="itinerary-container">
      <h3 className="itinerary-title">{t('Itinerary')}</h3>
      <div className="itinerary-days">
        {tripData.itinerary.map((item, index) => (
          <div className="itinerary-day" key={index}>
            <h3 style={{ padding: '10px' }}>{item.day}</h3>
            <p>{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  </Col>
</Row>


        
        <Row className="mt-4 prices-section">
          <Col>
            <h2 className="section-title">{t('Prices & Accommodation')}</h2>
            <Row>
              <Col md={6}>
                <Card className="price-card">
                  <Card.Header className="price-card-header">{t('Price')}</Card.Header>
                  <Card.Body>
                    <ul className="price-list">
                      <li>
                        <strong>{t('Standard Price')}</strong>
                        <div>{t('Per Single Person for: ')}<span className="price">US$ {tripData.price1}</span></div>
                        <div>{t('Per Person For 2 persons:')} <span className="price">US$ {tripData.price2}</span></div>
                      </li>
                      <li>
                        <strong>{t('Premium Price')}</strong>
                        <div>{t('Per Person for 3 to 4 People:')} <span className="price">US$ {tripData.pricee}</span></div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="price-card">
                  <Card.Header className="price-card-header">{t('Offers')}</Card.Header>
                  <Card.Body>
                    <ul className="price-list">
                      <li>
                        <strong>{t('Offer Name')}</strong>
                        <div>{tripData.offerName}</div>
                        <div>{tripData.offerDes}</div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Booking Confirmed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('Your booking has been confirmed!')}</p>
          <Button variant="primary" onClick={handleCloseConfirmation}>
            {t('OK')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TripDetails;
