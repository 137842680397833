import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import "../../Assets/css/spinner.css";
import "../../css/avatar.css";
import { getAuthUser } from '../../Helper/Storage';
import { Link, useLocation } from 'react-router-dom';

const Auth = getAuthUser();

const ViewBookings = () => {
  const [bookings, setBookings] = useState({
    loading: true,
    results: [],
    err: null,
    reload: 0,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [activeTab, setActiveTab] = useState('pending');
  
  const location = useLocation();

  useEffect(() => {
    setBookings((prevState) => ({ ...prevState, loading: true }));
    axios.get("https://api.goldenpharaohtours.com/getbookinglist")
      .then((resp) => {
        setBookings((prevState) => ({ ...prevState, results: resp.data, loading: false, err: null }));
      })
      .catch(() => {
        setBookings((prevState) => ({ ...prevState, loading: false, err: 'Something Went Wrong' }));
      });
  }, [bookings.reload]);

  useEffect(() => {
    if (location.pathname.includes('accepted')) {
      setActiveTab('accepted');
    } else if (location.pathname.includes('rejected')) {
      setActiveTab('rejected');
    } else if (location.pathname.includes('completed')) {
      setActiveTab('completed');
    } else {
      setActiveTab('pending');
    }
  }, [location.pathname]);

  const handleAction = (bookingId, type) => {
    setSelectedBookingId(bookingId);
    setActionType(type);
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (confirmed) => {
    setShowConfirmationModal(false);

    if (confirmed && selectedBookingId && actionType) {
      let url;
      switch (actionType) {
        case 'accept':
          url = `https://api.goldenpharaohtours.com/acceptbook/${selectedBookingId}`;
          break;
        case 'reject':
          url = `https://api.goldenpharaohtours.com/rejectbook/${selectedBookingId}`;
          break;
        case 'delete':
          url = `https://api.goldenpharaohtours.com/deletebook/${selectedBookingId}`;
          break;
        case 'complete':
          url = `https://api.goldenpharaohtours.com/completebook/${selectedBookingId}`;
          break;
        default:
          return;
      }

      try {
        await axios.get(url);
        setBookings((prevState) => ({ ...prevState, reload: prevState.reload + 1 }));
      } catch (error) {
        console.error(`An error occurred while processing the booking: ${actionType}.`, error);
      }
    }
  };

  const openWhatsApp = (phoneNumber) => {
    window.open(`https://wa.me/${phoneNumber}`);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date) ? 'N/A' : date.toISOString().split('T')[0];
  };

  const filteredBookings = bookings.results.filter((booking) => {
    const status = booking.statues ? booking.statues.trim() : '';
    if (activeTab === 'pending') {
      return status === '' || status === 'pending';
    }
    return status === activeTab;
  });

  return (
    <div className="site-section" data-aos="fade-up">
      <div className="container">
        <Nav variant="tabs" className="custom-nav" style={{ margin: '50px 0' }}>
        <Nav.Item>
            <Nav.Link
              className={activeTab === 'pending' ? 'active' : ''}
              onClick={() => setActiveTab('pending')}
            >
              Pending
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={activeTab === 'accepted' ? 'active' : ''}
              onClick={() => setActiveTab('accepted')}
            >
              Accepted
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={activeTab === 'rejected' ? 'active' : ''}
              onClick={() => setActiveTab('rejected')}
            >
              Rejected
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={activeTab === 'completed' ? 'active' : ''}
              onClick={() => setActiveTab('completed')}
            >
              Completed
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="table-responsive mt-3">
          <Table striped bordered hover className='table'>
            <thead>
              <tr>
              <th>Order ID</th>
                <th>Client Name</th>
                <th>Destination</th>
                <th>Phone</th>
                <th>Email</th>
                <th>From</th>
                <th>To</th>
                <th>Price</th>
                <th>Nationality</th>
                <th>Number of Adults</th>
                <th>Number of Children</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings.map((book) => (
                <tr key={book.id}>
                  <td>{book.id}</td>
                  <td>{book.name}</td>
                  <td>{book.destination}</td> {/* Adjust this if you have the actual trip name */}
                  <td>
                    <a href="#" onClick={() => openWhatsApp(book.mobile)}>
                      {book.mobile}
                    </a>
                  </td>
                  <td>{book.email}</td>
                  <td>{formatDate(book.fromm)}</td>
                  <td>{formatDate(book.too)}</td>
                  <td>{book.price}</td>
                  <td>{book.nationality}</td>
                  <td>{book.numberofadults}</td>
                  <td>{book.nnumberofchilds}</td>
                  <td>{book.note}</td>
                  <td className="action-buttons">
                    {book.statues !== 'completed' && (
                      <>
                        {book.statues !== 'accepted' && book.statues !== 'rejected' && (
                          <>
                            <Button variant="success" size="sm" onClick={() => handleAction(book.id, 'accept')}>Accept</Button>
                            <Button variant="danger" size="sm" onClick={() => handleAction(book.id, 'reject')}>Reject</Button>
                          </>
                        )}
                        {book.statues === 'accepted' && (
                          <>
                          <Button variant="info" size="sm" onClick={() => handleAction(book.id, 'complete')}>Complete</Button>
                          </>
                        )}
                        <Button variant="danger" size="sm" onClick={() => handleAction(book.id, 'delete')}>Delete</Button>

                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {bookings.loading && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner">&#9765;</div>
            <span>Loading...</span>
          </div>
        </div>
      )}

      <Modal show={showConfirmationModal} onHide={() => handleConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to proceed with this action?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleConfirmation(false)}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleConfirmation(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .custom-nav .nav-link {
          color: #ffc107 !important; /* Warning color */
          border-radius: 5px;
          margin-right: 5px;
          transition: background-color 0.3s;
        }

        .custom-nav .nav-link.active {
          background-color: #ffc107 !important; /* Active state warning color */
          color: #343a40 !important; /* Dark text on active */
        }

        .action-buttons {
          display: flex;
          gap: 10px;
        }

        .action-buttons a, .action-buttons button {
          margin-bottom: 5px;
          flex: 1;
        }

        @media (max-width: 767px) {
          .action-buttons {
            flex-direction: column;
          }

          .custom-nav .nav-link {
            display: block;
            margin-bottom: 10px;
            text-align: center;
          }
        }
      `}</style>
    </div>
  );
};

export default ViewBookings;
